import React from "react"
import { useStoryblokState } from "gatsby-source-storyblok"
import get from 'lodash/get'

import Image from "utils/Image"
import { Link } from 'gatsby'

import '../../static/styles/top-post-preview.css'

export default ({ node }) => {
  const testimonial = useStoryblokState(node);

  return (
    <div id={testimonial.slug} className="post-preview top-post-card d-flex flex-column">
      <div className="post-img-container">
        <Link to={`/testimonials/${testimonial.slug}`}>
          <div
            style={{
              width: '100%',
              height: '266px',
              position: 'relative',
              display: 'flex',
              flexDirection: 'row !important',
              backgroundClip: 'border-box',
            }}
          >
            {testimonial.content.hero && (
              <Image img={testimonial.content.hero} alt={testimonial.name} style={{width: '100%', height: '100%', objectFit: 'cover'}} />
            )}
          </div>
        </Link>
      </div>
      <div className="preview-contents flex-grow-1 d-flex flex-column">
        <div className="pre-title-group">
          <span className="post-category text-uppercase">
            {testimonial.content.industry} Testimonial
          </span>
        </div>
        <h3>
          <Link to={`/testimonials/${testimonial.slug}`}>{get(node, 'name')}</Link>
        </h3>
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
          }}
          className="author-group"
        >
          <img
            src={`${testimonial.content.company_avatar?.filename}?q=75&auto=compress`}
            alt={testimonial.content.company_name}
            width={41}
            height={41}
            className="author-avatar img-fluid"
            style={{
              height: '41px',
              width: 'auto',
            }}
            loading="lazy"
          />
          <span className="author-name">{testimonial.content.company_name}</span>
        </div>
      </div>
    </div>
  )
}