import React from 'react'
import { Helmet } from 'react-helmet'
import { graphql } from 'gatsby'
import get from 'lodash/get'
import Seo from "components/Seo"

import Testimonials from '../components/Testimonials'

class TestimonialIndexTemplate extends React.Component {
  render() {
    const testimonials = get(this, 'props.data.testimonials.edges')
    const userHighlights = get(this, 'props.data.userHighlights.edges')
    const pageContext = get(this, 'props.pageContext')

    return (
      <>
        <Seo
          title="Customer Stories | QuickMail"
          description="Learn the best tips on cold outreach and email outreach from QuickMail customers."
          url="/testimonials"
        />
        <Testimonials
          testimonials={testimonials}
          userHighlights={userHighlights}
          pageContext={pageContext}
        />
      </>
    )
  }
}

export default TestimonialIndexTemplate

export const TestimonialPageQuery = graphql`query TestimonialIndexQuery($skip: Int!, $limit: Int!) {
  testimonials: allStoryblokEntry(
    filter: { field_component: { eq: "testimonial" } }
    sort: {order: [DESC], fields: [published_at]}
    limit: $limit
    skip: $skip
  ) {
    edges {
      node {
        content
        slug
        name
      }
    }
  }
  userHighlights: allStoryblokEntry(
    sort: {order: [DESC], fields: [published_at]}
    filter: {
      field_component: { eq: "post" },
      content: { regex: "/\"categories\":\\[.*\"slug\":\"user-highlight\".*\\]/" }
    }
    limit: 4
  ) {
    edges {
      node {
        slug
        name
        content
        published_at(formatString: "MMMM D, YYYY")
      }
    }
  }
}
`
