import React from 'react'

import ResourceBanner from './ResourceBanner'
import TestimonialPreview from './TestimonialPreview'
import PostPreview from './PostPreview'
import Pagination from './Pagination'

import '../../static/styles/blog.css'
import '@fontsource/manrope/300.css'
import '@fontsource/open-sans/400-italic.css'

export default ({ testimonials, userHighlights, pageContext }) => (
  <>
    <div className="container padded p-relative" style={{ zIndex: '10' }}>
      <section className="row d-flex justify-items-between" id="insights">
        <div className="col-12 col-md-7 d-flex align-items-end">
          <h2 className="title">
            QuickMail&nbsp;
            <span
              style={{
                color: '#8D90AD',
              }}
            >
              Testimonials
            </span>
          </h2>
        </div>
        <div className="col-12 col-md-5 col-xl-5 d-flex align-items-end">
          <p className="details">
            Learn the best tips from QuickMail customers.
          </p>
        </div>
        <div className="col-12">
          <ResourceBanner />
        </div>
        {testimonials.map(({ node }) => {
          return (
            <div
              className="col-12 col-md-6 post-preview-container"
              key={node.slug}
            >
              <TestimonialPreview node={node} />
            </div>
          )
        })}
      </section>
    </div>
    <div className="diagonal-group blue-diagonal-across">
      <div className="container padded">
        <section className="row" id="deep-dive">
          <div className="col-12">
            <h3>Related Stories</h3>
          </div>
          {userHighlights.map(({ node }) => {
            return (
              <div
                className="col-12 col-md-6 post-preview-container"
                key={node.slug}
              >
                <PostPreview node={node} />
              </div>
            )
          })}
        </section>
        {pageContext.numberOfPages > 1 && (
          <div className="pagination-wrapper">
            <Pagination pageContext={pageContext} pathFromRoot="/blog" />
          </div>
        )}
      </div>
    </div>
  </>
)
